<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3>{{ $t('projects-impact.updateStudiesStates.title') }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>

            <!-- Composition -->
            <v-col cols="12">
              <v-row no-gutters>

                <!-- Select states -->
                <v-col cols="12">
                  <v-select
                      outlined
                      :items="$t('constants.studyStates.list')"
                      :label="$t('projects-impact.updateStudiesStates.labels.state')"
                      hide-details
                      v-model="form.state"
                  />
                </v-col>

              </v-row>

            </v-col>
            <v-col cols="12">
              <AlertBar />
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
            class="mx-3"
            :is-loading-btn-save="isLoadingBtn.btnSave"
            @submit="customSubmit"
            @cancel="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin            from "@/mixins/dialog"
import formRules              from "@/mixins/formRules";

export default {
  name: "ProjectsImpactUpdateStudiesStatesDialog",

  mixins: [dialogMixin, formRules],

  components: {
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  props: {
    projectImpact: { type: Object, default: () => null }
  },

  data() {
    return {
      isLoadingBtn: {btnSave: false},

      defaultForm: {
        state: null
      }
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.$http.put(`/projects-impact/${this.projectImpact.id}/update-studies-states`,  this.form, {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
            .then(res => {
              this.$emit('close');
            })
            .catch(err => {
              this.$store.commit("alert/showErrorAxios", err);
            })
            .finally(() => {
              this.isLoadingBtn.btnSave = false;
            });
      }
    },
  },

  computed: {
  }
}
</script>

<style scoped>

</style>
